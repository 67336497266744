export const environment = {
  production: true,
  baseUrl: 'https://app.semscoop.com/api/v1/',
  reCaptchaSiteKey:'6Ld6ZmAcAAAAAFumLwfURm1OBfW4LjQ4jmcCAvCp',
  allowedClicksLimit: 3,
  stripeToken: 'pk_live_4UsP1VRfVFDYkJqsMTgcdAZj',
  copyright: {
    date: 2021,
  },
};
