import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}
  UserLogin(data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'login', data);
  }
  userlogged() {
    if (localStorage.getItem('user')) {
      return true;
    } else {
      return false;
    }
  }
  userInfo() {
    let user = {
      name: localStorage.getItem('userName'),
      email: localStorage.getItem('userEmail'),
      avatar: localStorage.getItem('userAvatar'),
      plan: localStorage.getItem('userPlan'),
    };
    return user;
  }
  getUser() {
    return this.http.get<any>(this.baseUrl + 'profile');
  }
  updateUser(data) {
    return this.http.post<any>(this.baseUrl + 'profile/update', data);
  }
  applyCoupon(data) {
    return this.http.post<any>(this.baseUrl + 'subscribe/coupon/check', data);
  }
  changePasswordUser(data) {
    return this.http.post<any>(this.baseUrl + 'profile/password/change', data);
  }
  removeUser() {
    return this.http.post<any>(this.baseUrl + 'profile/delete', null);
  }
  UserRegister(data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'register', data);
  }
  UserVerifyAccount(token): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'email/verify/' + token);
  }
  UserReset(email): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'password/email', email);
  }
  UserResetBody(data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'password/reset', data);
  }
  UserActivateRequest(data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'activate', data);
  }
  activateProccess(token): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'activate/' + token);
  }

  getuser() {
    return this.http.get<any>(this.baseUrl + 'user');
  }
  contactForm(data) {
    return this.http.post<any>(this.baseUrl + 'contact', data);
  }

  logout() {
    return this.http.post<any>(this.baseUrl + 'logout', null);
  }
}
