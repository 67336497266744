import { Injectable } from '@angular/core';
import { HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError , EMPTY  } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceHttpInterceptor implements HttpInterceptor {

  constructor(private router : Router, private user : UserService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
      request.clone({
      setHeaders: { 
        'Accept': 'application/json',
        'Accept-Language'  : 'en',
        'Access-Control-Allow-Origin': '*',
        'X-Requested-With':'XMLHttpRequest'
      }
    });
    if(localStorage.getItem("user")){
        request = this.addToken(request,"Bearer ",localStorage.getItem("user"));       
    
    }
return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                   
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                //  console.log(error);
               if(error.status == 401 || error.status == 0){
//               localStorage.removeItem("guest");
//               localStorage.removeItem("user");
//               localStorage.removeItem("userName");
//               localStorage.removeItem("userImage");
//                this.router.navigate(['/login']);
               }
                return throwError(error);
                //return EMPTY ;
            }));

  }
    private addToken(request: HttpRequest<any> ,authType : string, token: string) {
    return request.clone({
      setHeaders: { 
        'Accept': 'application/json',
        'Accept-Language'  : 'en',
        'Authorization':`${authType}` + `${token}`,
        'X-Requested-With':'XMLHttpRequest'
      }
    });
  }
}
